<template>
  <div class="desktopLayoutClass">
    <v-app>
      <div>
        <v-row justify="center">
          <v-dialog v-model="auth_error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
                 {{$t("Warning_Message.Unauthorized")}}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="closeDialogUnauthorized"
                >
                    {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
                 {{$t("Warning_Message.Something_Went_Wrong")}}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeDialogError">
                   {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="deleted" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
               {{$t("Warning_Message.Deleted")}}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="primary" text @click="closeDelete">
                 {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="confirm_delete" persistent max-width="290">
            <v-card>
              <v-card-title v-if="this.$i18n.locale == 'en'" style="word-break: normal">
                 {{$t("Warning_Message.Are_You_Sure_You_Want_To_Delete")}}"{{ dependent_name }}"  {{$t("Warning_Message.From_Your_Dependents")}}
              </v-card-title>
              <v-card-title v-else style="word-break: normal">
                "{{ dependent_name }}" {{$t("Warning_Message.Are_You_Sure_You_Want_To_Delete")}}

              </v-card-title>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                "
              >
                <div>
                  <v-card-actions>
                    <v-btn
                      class="primary"
                      text
                      @click="deleteDependent(dependent_id)"
                    >
                      {{$t("Warning_Message.Ok")}}
                    </v-btn>
                  </v-card-actions>
                </div>
                <v-card-actions>
                  <div>
                    <v-btn class="error" text @click="closeConfirmation">
                      {{$t("Warning_Message.Cancel_Additions")}}
                    </v-btn>
                  </div>
                </v-card-actions>
              </div>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

        <div class="mainDivMyFamily">
      <div
        v-show="show_header"
        style="
          display: flex;
          flex-direction: row;
          padding-top: 10px;
          padding-left: 5px;
          margin: -1px;
        "
        class="header"
        >
        <div style="display: flex; flex-direction: row; width: 100%">
          <div
            style="width: 10%; padding-top: 6px"
            align="left"
            v-on:click="redirectToUserProfile()"
          >
            <img
              src="https://img.icons8.com/material-outlined/20/000000/left.png"
            />
          </div>
          <div style="width: 90%" align="left">
            <span style="font-weight: 600; font-size: 1.4em"
              >{{ $t("Customer.MyFamily.Family_Members") }}</span
            >
          </div>
        </div>
      </div>
      </div>
      <v-col cols="12">
        <div class="mainDivMyFamily" style="padding-bottom:50px;" v-if="!isFamilyLoading">
          <div
            v-for="family_member in all_family_list"
            :key="family_member._id"
          >
            <v-card
            class="dataCardClass"
              style="
                box-shadow: 0px 8px 20px 0px #0547891a;
                border: 1px solid #e4f5ff;
                border-radius: 8px;
              "
            >
              <div style="margin: 15px;">
                <v-row no-gutters>
                  <v-col cols="12" sm="12">
                    <v-row no-gutters>
                      <v-col cols="2" sm="2" style="text-align: left">
                        <img
                          src="https://img.icons8.com/color/50/000000/user.png"
                        />
                      </v-col>
                      <v-col cols="7" sm="5">
                        <h4 align="left" style="font-weight: 300;font-size: 0.7em">
                          {{ family_member.dependent_customer_relationship }}
                        </h4>
                        <div
                          style="font-weight: 600; font-size: 0.7em"
                          align="left"
                        >
                          {{ family_member.dependent_name }}
                        </div>
                      </v-col>
                      <v-col class="iconDiv" style="padding-left:0px">
                        <img
                          src="https://s3iconimages.mymedicine.com.mm/family_edit.svg"
                          @click="editDependent(family_member._id)"
                        />
                      </v-col>
                      <v-col style="padding-right: 10px;" >
                        <img
                          src="https://s3iconimages.mymedicine.com.mm/family_delete.svg"
                          @click="
                            show_confirmation(
                              family_member._id,
                              family_member.dependent_name
                            )
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="MobileLayout">

                  <v-col cols="6" sm="6">
                    <div style="display: flex; flex-direction: row">
                      <div>
                        <img
                          src="https://s3iconimages.mymedicine.com.mm/calendar.svg"
                        />
                      </div>
                      <div align="left">
                        <span
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          {{ $t("Customer.MyFamily.Age") }}: {{ family_member.dependent_age }}</span
                        >
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <div style="display: flex; flex-direction: row">
                      <div>
                        <img
                          src="https://s3iconimages.mymedicine.com.mm/gender.svg"
                        />
                      </div>
                      <div>
                        <span
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          {{ $t("Customer.MyFamily.Gender") }}: {{ family_member.dependent_gender }}
                        </span>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <div style="display: flex; flex-direction: row">
                      <div>
                        <img
                          src="https://s3iconimages.mymedicine.com.mm/weight.svg"
                        />
                      </div>
                      <div align="left">
                        <span
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          {{ $t("Customer.MyFamily.Weight") }}: {{ family_member.dependent_weight }} Lbs</span
                        >
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <div style="display: flex; flex-direction: row">
                      <div>
                        <img
                          src="https://s3iconimages.mymedicine.com.mm/height.svg"
                        />
                      </div>
                      <div>
                        <span
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          {{ $t("Customer.MyFamily.Height") }}: {{ family_member.dependent_height }} </span
                        >
                      </div>
                    </div>
                  </v-col>

                </v-row>
                <v-row>
                  <div class="DesktopLayout" >
                    <div class="contentClass">
                      <div>
                        <img
                          src="https://s3iconimages.mymedicine.com.mm/calendar.svg"
                        />
                      </div>
                      <div align="left">
                        <span
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          {{ $t("Customer.MyFamily.Age") }}: {{ family_member.dependent_age }}</span
                        >
                      </div>
                    </div>
                    <div>
                      <div class="contentClass">
                      <div>
                        <img
                          src="https://s3iconimages.mymedicine.com.mm/gender.svg"
                        />
                      </div>
                      <div>
                        <span
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          {{ $t("Customer.MyFamily.Gender") }}: {{ family_member.dependent_gender }}
                        </span>
                      </div>
                    </div>
                    </div>
                    <div>
                       <div class="contentClass">
                      <div>
                        <img
                          src="https://s3iconimages.mymedicine.com.mm/weight.svg"
                        />
                      </div>
                      <div align="left">
                        <span
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          {{ $t("Customer.MyFamily.Weight") }}: {{ family_member.dependent_weight }} Lbs</span
                        >
                      </div>
                    </div>
                    </div>
                    <div>
                      <div class="contentClass">
                      <div>
                        <img
                          src="https://s3iconimages.mymedicine.com.mm/height.svg"
                        />
                      </div>
                      <div>
                        <span
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          {{ $t("Customer.MyFamily.Height") }}: {{ family_member.dependent_height }} </span
                        >
                      </div>
                    </div>
                    </div>
                  </div>
                </v-row>
              </div>
            </v-card>
            <br />
          </div>
        </div>

        <v-progress-circular
          indeterminate
          color="primary"
          v-else
        ></v-progress-circular>
      </v-col>
      <div class="ButtonClass">
        <v-btn class="vbtnclass" color="primary" @click="redirectToAddDependent"
          >{{ $t("Customer.MyFamily.Add_New_Dependent") }}</v-btn>
      </div>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import bottomnavbar from "./bottomnavbar.vue";
export default {
  name: "MyFamily",
  data() {
    return {
      isFamilyLoading: true,
      user_id: "",
      pageName: "myFamily",
      currentCustomer: "",
      customer_name: "",
      auth_error_enable: false,
      error_enable: false,
      confirm_delete: false,
      all_family_list: [],
      dependent_name: "",
      isMobile : false,
      Relationship_list: [{"key":"parent","mm_text":"မိဘ","en_text":"Parent"},{"key":"child","mm_text":"သားသမီး","en_text":"Child"},{"key":"spouse","mm_text":"အိမ်ထောင်ဖက်","en_text":"Spouse"},{"key":"sibling","mm_text":"မောင်နှမ","en_text":"Sibling"}],
      deleted: false,
      genderList :[{"key":"male","mm_text":"ကျား","en_text":"Male"},{"key":"female","mm_text":"မ","en_text":"Female"},{"key":"other","mm_text":"အခြား","en_text":"Other"}],
      show_header : true
    };
  },
  mounted() {
    //  var userAgent = "Mozilla/5.0 (Linux; Android 9; MI 6X Build/PKQ1.180904.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/76.0.3809.111 Mobile Safari/537.36; kbzpay app/customer platform/android lang/my unicode/true version/5.0.3"
    var userAgent = navigator.userAgent
    if(userAgent.includes("kbzpay")){
      this.show_header = false;
    }
    this.isMobile = this.$device.mobile;
    if(this.$store.state.locale == "en")
      document.title = "My Dependents"
    else
      document.title = "ကျွန်ုပ်၏ မှီခိုမိသားစုဝင်များ"
    this.$i18n.locale = this.$store.state.locale;
    this.currentCustomer = this.$cookies.get("customerToken");
    var access = this.$cookies.get("access");
    if (!this.currentCustomer) {
      this.$router.push({
        name: "Login",
      });
    } else {
      // var verifyCustomerTokenData = {
      //   token: this.currentCustomer,
      //   access: access,
      //   typeOfUser: "CUSTOMER",
      // };
      // axios
      //   .post(
      //     process.env.VUE_APP_BACKEND_URL + "/verifyCustomerToken",
      //     verifyCustomerTokenData
      //   )
      //   .then((verifyCustomerTokenDataResponse) => {
      //     this.customer_name =
      //       verifyCustomerTokenDataResponse.data.data.customer_name;
          var customer_body = {
            token: this.currentCustomer,
            typeOfUser: "CUSTOMER",
          };
          axios
            .post(
              process.env.VUE_APP_BACKEND_URL +
                "/fetchDependentFromCustomerId",
              customer_body
            )
            .then((fetchDendentFromCustomerIdResponse) => {
              // console.log("fetchDendentFromCustomerIdResponse", fetchDendentFromCustomerIdResponse.data.data)
              this.all_family_list = fetchDendentFromCustomerIdResponse.data.data;
              this.all_family_list.forEach((x) => {
                x.dependent_age = this.get_age(x.dependent_dob);
                var dependent_relationship = x.dependent_customer_relationship;
               let obj = this.Relationship_list.find(o => o.key === dependent_relationship.replace(" ","_").toLowerCase());
                 var dependentGender = x.dependent_gender;
               let Genderobj = this.genderList.find(o => o.key === dependentGender.replace(" ","_").toLowerCase());
                if(this.$i18n.locale == 'en')
                {
                  x.dependent_customer_relationship = obj.en_text;
                  x.dependent_gender = Genderobj.en_text
                }
                else
                {
                  x.dependent_customer_relationship = obj.mm_text;
                   x.dependent_gender = Genderobj.mm_text
                }
              });
              this.isFamilyLoading = false;
              this.userPageViewTable();
            })
            .catch((fetchDependentFromCustomerIdError) => {
              console.log("fetchDependentFromCustomerIdError", fetchDependentFromCustomerIdError)
              if (
                fetchDependentFromCustomerIdError.response.status == 401
              ) {
                this.auth_error_enable = true;
              } else {
                this.error_enable = true;
              }
            });
        // })
        // .catch((verifyCustomerTokenDataError) => {
        //   if (verifyCustomerTokenDataError.response.status == 401) {
        //     this.auth_error_enable = true;
        //   } else {
        //     this.error_enable = true;
        //   }
        // });
    }
  },
  components: {
    NavBar,
    bottomnavbar,
  },
  methods: {
    closeConfirmation() {
      this.confirm_delete = false;
    },
    closeDelete() {
      this.deleted = false;
      this.$router.go();
    },
    show_confirmation(family_member_id, dependent_name) {
      this.dependent_id = family_member_id;
      this.dependent_name = dependent_name;
      this.confirm_delete = true;
    },
    closeDialogUnauthorized() {
      this.auth_error_enable = false;
      this.$router.push({
        name: "Login",
      });
    },
    closeDialogError() {
      this.error_enable = false;
    },
    redirectToUserProfile() {
      this.$router.go(-1);
    },
    get_age(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    editDependent(dependent_id) {
      this.$router.push({
        path: "/customer/editDependent/" + dependent_id,
      });
    },
    deleteDependent(dependent_id) {
      this.confirm_delete = false;
      var deleteDependentBody = {
        token: this.currentCustomer,
        typeOfUser: "CUSTOMER",
        dependentId: dependent_id,
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/removeDependentForCustomerId",
          deleteDependentBody
        )
        .then((deleteDependentResponse) => {
          this.deleted = true;
          //this.$router.go();
        })
        .catch((deleteDependentError) => {
          alert(deleteDependentError);
        });
    },
    redirectToAddDependent() {
      this.$router.push({
        name: "AddDependent",
      });
    },
    userPageViewTable() {
            if (this.currentCustomer === null) {
                var userPageViewTableBody = {
                pageName : 'myDependents',
                typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                pageName: 'myDependents',
                typeOfUser: 'CUSTOMER',
                token: this.currentCustomer
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
    },
  },
};
</script>

<style scoped>
td,
th {
  padding: 10px;
}

.DesktopLayout{
  display: none !important;
}

.header {
  display: block;
  box-shadow: 0px 2px 15px 0px #00000026;
  padding: 16px 20px !important;
  background-color: white;
}
.toggleClass {
  justify-content: center;
  margin-left: 24%;
  width: 75%;
}
.ButtonClass
{
  position: fixed;
  bottom: 0;
  margin-bottom: 5%;
  width: 100%;
  padding: 0px 12px;
  max-width: 425px;
}
.vbtnclass
{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 24px 0 !important;
}
.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 48px !important;
  width: 100% !important;
  min-width: 0 !important;
}
.activeClass {
  border-radius: 8px !important;
  color: white !important;
  background-color: #1467bf !important;
}
.cardClass {
  margin-top: 5%;
  height: 30%;
}
.card-footer {
  position: relative;
  align-content: center;
  background-color: #fecdbe;
  padding-top: -1vh;
  margin: 0vh 0vh 0vh -2vh;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  text-align: center;
  width: 90vw;
  height: 4vh;
  font-size: 9pt;
}
::-webkit-scrollbar {
  display: none;
}
/* @media (min-width: 800px){
  .mainDivMyFamily{
   width: 800px !important;
   margin-left: 30vw !important ;
   height: auto;

  }
  .iconDiv{
    margin-right:0px;
    margin-left: 300px !important;
  }
  .dataCardClass{
    width: 80%;
    margin-left: 10% !important;
  }
  .ButtonClass{
    position: sticky;
    bottom: 0;
    margin-bottom: 0% !important;
    width: 100%;
  }
  .MobileLayout{
    display: none;
  }
  .DesktopLayout{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-evenly !important;
    padding-left: 5% !important;
  }
  .contentClass{
    display: flex !important;
    flex-direction: row !important;
    margin-right: 35px !important;
  }
  } */
</style>